<template>
<b-container fluid class="bg-white py-5">
  <b-container v-if="!loadingNews">
    <b-row>
      <b-col>
        <b-button class="mr-2" variant="outline-primary" :to="{ name: 'Articles' }">insights</b-button>
        <span class="mb-4" v-if="permissions.edit">
          <b-button class="mr-2" variant="outline-primary" :to="{ name: 'WorkflowArticle', params: {id: article.id } }">edit</b-button>
          workflow stage: <strong>{{workflow.find(x => x.id === article.stage).name}}</strong>
        </span>
      </b-col>
      <b-col class="text-right">
        <b-button class="mr-2 text-right" variant="outline-primary" @click="$router.go(-1)">back</b-button>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <h1 class="ml-0 my-4">{{article.title}}</h1>
        <div class="mb-4">
          {{ moment(article.approved_at).format('YYYY-MM-DD') }}
          <span v-for="(author, index) in authors" :key="author">
            <a :href="'mailto:' + author">{{author}}</a><span v-if="index + 1 < authors.length">, </span>
          </span>
        </div>
        <div class="mb-2 font-size-smaller" v-if="article.events.length > 0">
          <strong>Events: </strong>
          <li class="ml-4" v-for="(event) in article.events" :key="'event' + event.id">
            <router-link :to="{ name: 'Events', params: { id: event.id}}" ><strong>{{ moment(event.date).format('YYYY-MM-DD') }}</strong> - {{event.name}}</router-link><a v-if="article.events.length > 1"></a>
          </li>
        </div>
        <div class="mb-2 font-size-smaller" v-if="article.regtopics.length > 0">
          <strong>Topics: </strong>
          <span v-for="(topic, index) in article.regtopics" :key="'topic' + topic.id">
            <router-link :to="{ name: 'Topic', params: { id: topic.id}}" >{{topic.name}}</router-link><span v-if="index + 1 < article.regtopics.length"> | </span>
          </span>
        </div>
        <div class="mb-2 font-size-smaller" v-if="article.risks.length > 0">
          <strong>Risks: </strong>
          <span v-for="(risk, index) in article.risks" :key="'risk' + risk.id">
            <router-link :to="{ name: 'Risk', params: { id: risk.id}}" >{{risk.name}}</router-link><span v-if="index + 1 < article.risks.length"> | </span>
          </span>
        </div>
        <div class="mb-2 font-size-smaller" v-if="article.tags.length > 0">
          <strong>Types: </strong>
          <span v-for="(tag, index) in article.tags" :key="'tag' + tag.id">
            <router-link :to="{ name: 'NewsTag', params: { id: tag.id}}" >{{ tag.name }}</router-link><span v-if="index + 1 < article.tags.length"> | </span>
          </span>
        </div>
        <div class="mb-5 font-size-smaller" v-if="article.publications.length > 0">
          <strong>News: </strong>
          <span v-for="(publication, index) in article.publications" :key="'publication' + publication.id">
            <router-link :to="{ name: 'NewsItem', params: { id: publication.id}}" >{{publication.name}}</router-link><span v-if="index + 1 < article.publications.length"> | </span>
          </span>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="8">
        <div v-if="article.summary">
          <p style="font-size: 1.2em">{{article.summary}}</p>
        </div>
        <div v-if="article.body">
          <div v-html="article.body"></div>
        </div>
        <div v-if="article.implications">
          <p><strong>Implications</strong></p>
          <div v-html="article.implications"></div>
        </div>
      </b-col>
      <b-col cols="4" class="p-4" style="color: white; background-color: #00348d" v-if="!loadingRelated && related.length > 0">
        <div class="mb-4"><strong>You may also be interested in</strong></div>
        <div class="mb-4" v-for="item in related" :key="'related' + item.id">
          <b-link :to="{ name: 'Article', params: { id: item.id } }" style="color: white">{{item.title}}</b-link>
        </div>
      </b-col>
    </b-row>
    <b-row v-if="!loadingNewsItem && newsitem" class="mt-5">
      <b-col>
        <b-card>
          <publication
            :propItem="newsitem"
            :hideLink="true"
            :key="newsitem.id"
            :tagsProp=tags
            :username="user.username"
            :permissionAddArticle="false"
            :permissionAddRisk="false"
            :permissionAddTag="false"
            :permissionInsights="false"
            :permissionDeletePublication="false"
            :permissionReadTagsPanel="false"
              />
        </b-card>
      </b-col>
    </b-row>
    <b-row v-if="loadingNewsItem" class="text-center mb-4" align-h="center">
      <b-col cols="1">
        loadingNewsItem
        <div><i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i></div>
      </b-col>
    </b-row>
    <b-row class="mb-4" v-if="!loadingRelatedNews && relatedNews">
      <b-col class="text-white bg-kpmg-blue py-4 m-4">
        <h5 class="card-title font-kpmg font-size-44">More from {{newsitem.publisher}}</h5>
        <ul>
          <li v-for="item in relatedNews.recentByPublisher" :key="'startnews' + item.id" class="text-white">
            <a :href="item.link" target="_blank"><i class="fa fa-external-link" aria-hidden="true" style="color: white;"></i></a>&nbsp;
            <router-link class="text-white" :to="{ name: 'NewsItem', params: { id: item.id}}" >{{item.name}} <small> - {{item.date}} </small></router-link>
          </li>
        </ul>
      </b-col>
      <b-col class="text-white bg-kpmg-dark-blue py-4 m-4" v-if="relatedTopics && regTopics.length > 0">
        <h5 class="card-title font-kpmg font-size-44">More on related topics</h5>
        <div v-for="(regtopicName, index) in regTopics" :key="index" class="mb-2">
          <h5> {{ regtopicName }} </h5>
          <ul>
            <li v-for="(headline, innerIndex) in relatedByTopic(regtopicName)" :key="innerIndex">
              <a :href="newsitem.link" target="_blank"><i class="fa fa-external-link" aria-hidden="false" style="color: white;"></i></a>&nbsp;
              <router-link class="text-white" :to="{ name: 'NewsItem', params: { id: headline.id}}" >{{headline.name}} <small>  - {{headline.date}} - {{headline.publisher}}</small></router-link>
            </li>
          </ul>
        </div>
      </b-col>
    </b-row>
    <b-row v-if="loadingRelatedNews" class="text-center mb-4" align-h="center">
      <b-col cols="1">
        loadingRelatedNews
        <div><i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i></div>
      </b-col>
    </b-row>
  </b-container>
  <b-container v-if="loadingNewsItem">
    <b-row class="text-center mb-4" align-h="center">
      <b-col cols="1">
        loadingNewsItem
        <div><i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i></div>
      </b-col>
    </b-row>
  </b-container>
      <h1 v-if=loadingPdf class="mt-4 center"><i class="fa fa-spinner fa-pulse fa-lg fa-fw text-center" align-v="center"></i>Loading document...</h1>
      <div class="center mt-4 pdfViewer page" v-for="page in pages" :key="'page-'+ page" :id="'page' + page">
        <div class="canvasWrapper"><canvas :id="'pdfpage'+ page"></canvas></div>
      </div>
</b-container>
</template>

<script>
import _ from 'lodash'
import async from 'async'
import moment from 'moment'
import ac from '../libs/accesscontrol'
import Publication from '@/components/PublicationNew'
import workflow from '@/workflows/article'
const pdfjs = require('pdfjs-dist/legacy/build/pdf.js')

// eslint-disable-next-line
const PdfjsWorker = require("worker-loader?esModule=false&filename=[name].js!pdfjs-dist/legacy/build/pdf.worker.js")

if (typeof window !== "undefined" && "Worker" in window) {
  pdfjs.GlobalWorkerOptions.workerPort = new PdfjsWorker()
}

function delay (period) {
  return new Promise(resolve => {
    setTimeout(resolve, period)
  })
}

let pdf

const render = async function (pageNumber) {
  try {
    const page = await pdf.getPage(pageNumber)
    const viewport = page.getViewport({ scale: 1.7 })
    const canvas = document.getElementById('pdfpage' + pageNumber)
    const context = canvas.getContext('2d')
    canvas.height = viewport.height
    canvas.width = viewport.width
    const renderContex = {
      canvasContext: context,
      viewport: viewport
    }
    await page.render(renderContex)
  } catch (e) {
    console.log(e)
  }
}

export default {
  components: {
    Publication
  },
  computed: {
    regTopics () {
      const topics = new Set()
      _.each(this.relatedNews["relatedByTopic"], topic => { topics.add(topic.regtopicName) })
      return Array.from(topics)
    },
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  created: async function () {
    this.permissions.edit = ac.can(this.user.acgroups).createAny('articleWorkflowEdit').granted
    this.tags = await this.$Amplify.API.get('cosmos', '/tags')
    this.firstLoad()
  },
  data () {
    return {
      formEventValidated: false,
      gridLoaderColor: 'black',
      gridLoaderSize: '10px',
      article: {},
      authors: {},
      loadingNews: true,
      loadingNewsItem: true,
      loadingRelated: true,
      loadingRelatedNews: true,
      moment: moment,
      newsitem: null,
      permissions: {},
      workflow: workflow,
      showPdf: false,
      ready: false,
      related: [],
      relatedTopics: false,
      relatedNews: null,
      loading: true,
      loadingPdf: false,
      pdfUrl: '',
      pages: [],
      pdfUploaded: false,
      tags: []
    }
  },
  methods: {
    firstLoad: async function () {
      console.log('firstLoad')
      this.loadingNews = true
      this.loadingNewsItem = true
      this.loadingRelated = true
      this.$logger.debug('first load started')
      try {
        let response = await this.$Amplify.API.get('cosmos', `/article/v2/${this.$route.params.id}`)
        this.$logger.debug('firstLoad:')
        this.$logger.debug({ response })
        this.article = response.article
        document.title = "KPMG Regulatory Horizon: " + this.article.title
        console.log(this.article)
        let authors = []
        _.each(response.article_history, x => {
          authors.push(x.created_by)
        })
        this.authors = _.sortedUniq(authors)
        if (!this.article.approved_by) {
          if (!this.permissions.edit) {
            this.article.title = "Permission not granted"
            this.article.body = "<p>You do not have permissions to see this article</p>"
          }
        }
        if (this.article.pdf_uploaded) {
          this.loadPdf()
        }
        this.loadingNews = false
        this.related = await this.$Amplify.API.get('cosmos', `/article/${this.$route.params.id}/related`)
        this.loadingRelated = false
        this.$stat.log({ page: 'article', action: 'open article', model: 'article', model_id: this.$route.params.id })
        await this.firstLoadNews()
      } catch (e) {
        this.$logger.warn('saved ERROR: ' + e)
      }
    },
    loadPdf: async function () {
      console.log('loadPDF')
      this.showPdf = true
      this.loadingPdf = true
      try {
        this.uploadUrl = {}
        let apiName = 'cosmos'
        let path = '/getObject/insights/pdf/' + this.article.id + '.pdf'
        this.pdfUrl = await this.$Amplify.API.get(apiName, path)
        // render PDF
        const pdfTask = pdfjs.getDocument(this.pdfUrl.URL)
        pdf = await pdfTask.promise
        const pages = pdf.numPages
        for (let i = 1; i <= pages; i++) {
          this.pages.push(i)
        }
        this.ready = true
        await delay(100)
        await async.eachSeries(this.pages, async.asyncify(render))
        this.$emit('render-completed')
        this.loadingPdf = false
      } catch (e) {
        console.log(e)
        this.loadingPdf = false
      }
    },
    relatedByTopic (regTopic) {
      console.log('relatedByTopic')
      return this.relatedNews["relatedByTopic"].filter(item => item.regtopicName === regTopic)
    },
    firstLoadNews: async function () {
      this.$logger.debug('first load started')
      try {
        if (this.article.publications.length > 0) {
          console.log('fetching /news/item...')
          this.newsitem = await this.$Amplify.API.get('cosmos', `/news/item/${this.article.publications[0].id}`)
          console.log('newsitem')
          console.log(this.newsitem)
          this.loadingNewsItem = false
          await this.relatedItems()
        } else {
          console.log('nothing to fetch')
          this.loadingNewsItem = false
          this.loadingRelatedNews = false
          this.relatedTopics = false
        }
      } catch (e) {
        this.$logger.warn('saved ERROR: ' + e)
        this.loadingNewsItem = false
      }
    },
    relatedItems: async function () {
      try {
        this.relatedNews = ""
        this.loadingRelatedNews = true
        this.relatedTopics = false
        let topicsList = []
        _.each(this.newsitem.regtopics, item => {
          topicsList.push(item.id)
        })
        if (topicsList.length > 0) {
          let params = {
            body: {
              publisher: this.newsitem.publisher,
              topics: topicsList
            }
          }
          this.relatedNews = await this.$Amplify.API.post('cosmos', `/news/item/related/${this.newsitem.id}/5`, params)
          this.loadingRelatedNews = false
          this.relatedTopics = true
        } else {
          this.loadingRelatedNews = false
          this.relatedTopics = false
        }
      } catch (e) {
        this.$logger.warn('saved ERROR: ' + e)
        this.loadingRelatedNews = false
      }
    }
  },
  watch: {
    '$route.params.id': function () {
      this.firstLoad()
    }
  }
}
</script>

<style>
.center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.pdfViewer {
  padding-bottom: 5px;
}

.pdfViewer .canvasWrapper {
  overflow: hidden;
}

.pdfViewer .page {
  direction: ltr;
  margin: 10px;
  position: relative;
  overflow: visible;
  border: 10px solid;
  background-clip: content-box;
  border-image: url('/img/shadow.png') 9 9 repeat;
  background-color: rgba(255, 255, 255, 1);
}

.pdfViewer .page canvas {
  margin: 0;
  display: block;
}
</style>
